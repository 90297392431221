.PublicationList {
  width: 100%;
  height: 100%;
  padding: $grid-margin;

  & .Edit {
    grid-template-rows: auto auto;
  }

  > * {
    margin: 0;
  }

  .Addon.icon-addon {
    width: $line-height*3;
    text-align: center;
    font-size: $font-size*2;

    > .Icon {
      font-size: $font-size*2;
    }

    > img {
      width: $line-height*2;
      height: $line-height*2;
      vertical-align: text-bottom;
      object-fit: contain;
    }
  }

  > .list {
    height: 100%;
    grid-area: list;
    display: grid;
    grid-gap: $grid-margin;
    grid-template-columns: minmax(240px, auto) 1fr auto auto auto;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'title search navigate pagesize reload' 'table table table table table';

    > .title {
      grid-area: title;

      > * {
        margin: 0;
      }

      > h2 {
        margin-bottom: $grid-margin*0.5;
      }

      > .sub {
        color: $color-text-secondary;
      }
    }

    > .table {
      grid-area: table;
      margin: 0;

      thead {
        white-space: nowrap;
      }

      td, th {
        cursor: pointer;
        white-space: nowrap;

        > img, i {
          margin-right: $grid-margin * 0.5;
        }
      }
    }

    > .search {
      grid-area: search;
    }

    > .navigate {
      grid-area: navigate;

      > .Input.type-select {
        width: $line-height*9;
      }
    }

    > .expand {
      grid-area: expand;
      display: none;
    }

    > .reload {
      grid-area: reload;
    }

    > .pagesize {
      grid-area: pagesize;
    }
  }

  > .actions {
    grid-area: actions;
    width: 240px;
    display: flex;
    flex-direction: column;

    > * {
      margin: 0;
    }

    > .editActions {
      flex: 1 1 0;
    }

    > .zoomActions {
      flex: 0 0 auto;
      margin-top: $grid-margin;
    }
  }

  > .main {
    grid-area: main;
    display: flex;
    flex-direction: row;

    > * {
      margin: 0;
    }
  }
}

.featurePublicationDetailsModal {
  .Body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    overflow: hidden;

    > .Table {
      height: 100%;
      width: 100%;
    }
  }

  .Footer {
    display: flex;

    > * {
      flex: 1 1 auto;
    }

    > .Button {
      flex: 0 0 auto;
      margin-left: $grid-margin;
    }
  }
}

.MasterDataZoom {
  > .Sheet {
    overflow: hidden !important;
    width: 100%;
    height: 100%;
  }

  @for $i from 0 through 32 {
    &.depth-#{$i} > .Sheet {
      width: calc(100% - #{$line-height*2.5*$i} - #{$grid-margin});
      height: calc(100% - #{$line-height*4.5*$i} - #{$grid-margin});
    }
  }
}
