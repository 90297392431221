.HousingAssistant {
  position: relative;
  margin: auto;
  max-width: 640px;
  display: flex;
  flex-direction: column;
  height: 100%;

  > h1 {
    margin-bottom: $line-height;
    flex: 0 0 auto;
  }

  .assistant {
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: grid;
    grid-gap: 0;
    grid-auto-flow: row;
    grid-template-areas: "header" "body" "footer" "void";
    grid-template-rows: auto minmax(0, 1fr) auto 0;
    @supports not (display: grid) {
      display: flex;
      flex-direction: column;
      > * {
        flex: 0 0 0;
      }
      > .Header {
        flex: 0 0 auto;
      }
      > .Body {
        flex: 1 1 0;
      }
      > .Footer {
        flex: 0 0 auto;
      }
    }

    > * {
      grid-area: void;
    }

    > .Header {
      grid-area: header;
      border-bottom: 1px solid $color-border;
    }

    > .Body {
      grid-area: body;
    }

    > .Footer {
      grid-area: footer;
    }

    > .Body ~ .Footer {
      border-top: 1px solid $color-border;
    }

    > .Header,
    > .Body,
    > .Footer {
      padding: $grid-margin;

      &.Body {
        padding-right: 0;
      }

      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
