.PartnerFormular {
  margin: $grid-margin;
}

.customerExistModal, .ownerExistModal {
  > .Sheet {
    width: 80%;
    min-width: 850px;

    > .Body {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      overflow: hidden;
      margin-bottom: 10px;

      > .div-explanation{
        margin-bottom: 20px;
      }
    }

    > .Footer {
      > .btn-left {
        float: left;
      }

      > .btn-right {
        float: right;
      }
    }
  }
}