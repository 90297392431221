@import url('https://fonts.googleapis.com/css?family=Cabin:400,400i,500,500i,600,600i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Indie+Flower&display=swap');

$border-radius: 4px;
//$grid-margin: 6px;
//$font-size: 10px;
//$line-height: 12px;
$font-family: Cabin, sans-serif;
/*$color-text: #333;
$color-text-secondary: #999;
$color-text-light: #fff;
$color-text-placeholder: #999;
$color-background: #f8f8f8;
$color-border: #e8e8e8;*/
/*$color-primary: #d8717f;
$color-info: #8dbaea;
$color-success: #87b749;
$color-warn: #edb100;
$color-error: #a53745;*/
// $color-primary: #6197c5;
$color-brand: #014163;
$color-primary: $color-brand;
$color-background: #f8f8f8;
$color-text: #4a4a4a;
$color-text-secondary: #808080;
$color-text-light: #fff;
$color-text-light-secondary: rgba(#fff, 0.7);
$color-border: #dadada;
$color-text-placeholder: #a3a3a3;
@import '../../node_modules/@mdsi/aurum/theme/default.scss';
.Sheet {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
}

.Button {
  text-transform: none !important;
}

.clickable {
  cursor: pointer !important;
}

// @import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import './component/App.scss';
@import './component/ErrorBound.scss';
@import './component/Edit.scss';
@import './component/booking/BookingList.scss';
@import './component/booking/BookingDetailsModal.scss';
@import './component/booking/rebooking/RebookingTransportationParent.scss';
@import './component/booking/rebooking/RebookingTransportationChild.scss';
@import './component/BookingCalendar.scss';
@import './component/MessageList.scss';
@import './component/Topmenu.scss';
@import './component/Menu.scss';
@import './component/Dashboard.scss';
@import './component/HousingAssistant.scss';
@import './component/BookingPlan.scss';
@import './component/PublicationList.scss';
@import './component/PublicationRequestList.scss';
@import './component/RegisterOwner';
@import './component/RegisterEmployee.scss';
@import './component/HousingListingTest.scss';
@import './component/TransportationListingTest.scss';
@import './component/OwnerConditionMerchant.scss';
@import './component/Support.scss';
@import './component/NewsletterReceiverList.scss';
@import './component/owner_payments/OwnerPayments.scss';
@import './component/SumOfUnexportedPositions.scss';
@import './component/channel_manager_protocol/PagedSearchTable';
@import './component/password_reset_modal/PasswordResetModal';
@import './component/voucher/Voucher';
@import './component/owner_payments/SalesStatistics';
@import './component/owner_payments/OwnerRevenues';
@import './component/product/FeatureProblems';
@import './component/voucher/VoucherStatistics';
@import './component/modal/SimpleModal';
@import './component/forms/HtmlPreviewForm.scss';
@import './component/forms/FileRefForm.scss';
@import './component/forms/FileRefFormPreviewModal.scss';
@import './component/datev/DatevList.scss';
@import './component/dac7export/Dac7ExportList.scss';
@import './component/payment/PaymentList.scss';
@import './component/paymentFibu/PaymentListFibu.scss';
@import './component/promocode/PromocodeListing.scss';
@import './component/readable_protocol/ReadableProtocol.scss';
@import './component/table/ExpandableTable.scss';
@import './component/tax/TaxModal.scss';
@import './component/product/FeatureProblems.scss';
@import './component/legitimations/CustomerContactPersonsWithBadLegitimations.scss';
