.Menu {
  z-index: 2;
  border-radius: 0;
  margin: 0;
  width: 240px;
  display: flex;
  flex-direction: column;
  padding: 0;
  transition: all 0.25s ease-in-out;

  > .left {
    flex: 0 1 auto;
    overflow: auto;
  }

  > .center {
    flex: 1 0 auto;
  }

  > .right {
    flex: 0 0 auto;
  }

  > .left,
  > .center,
  > .right {
    > * {
      display: block;
      margin: 0;
      border-radius: 0;
      width: 100%;
      background-color: transparent;
      text-align: left;
      border-left: 0 solid $color-primary;

      &:not(.Button) {
        padding: $grid-margin;
      }

      &.Button {
        height: 40px;
        padding-top: 4px;
        padding-bottom: 4px;
      }

      &.Button:not([disabled]) {
        font-weight: 400;
        box-shadow: none;
        color: $color-text-secondary;
        padding-left: $grid-margin;
        // transition: none;
        &:hover {
          background-color: $color-background;
        }

        &.type-active {
          color: $color-primary;
          border-left: $grid-margin*0.5 solid $color-primary;
          padding-left: $grid-margin*0.5;
        }

        @for $i from 1 through 32 {
          &.depth-#{$i} {
            padding-left: $grid-margin * 4 * $i;

            &.type-active {
              padding-left: $grid-margin * 4 * $i - $grid-margin*0.5;
            }
          }
        }

        > .Icon {
          padding-right: $grid-margin * 0.5;
        }
      }

      &.Button[disabled] {
        font-weight: 400;
        box-shadow: none;
        color: $color-text-placeholder;
      }
    }
  }
}
