.rebookingChild {
  > .Body {
    height: 100%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: grid;
    grid-gap: $grid-margin;
    grid-template-columns: 1fr 2fr;
    grid-template-areas: 'left right';

    .left {
      min-width: 200px;
      grid-area: left;

      .abba {
        width: 100%;
        overflow-y: scroll;

        .clickable {
          vertical-align: top;
        }

        .disable {
          color: grey;
          vertical-align: top;
        }

        .position-checkbox {
          vertical-align: top;
          width: 24px;

          .Input {
            box-shadow: none;
            min-height: 24px;
            min-width: 24px;
            width: 24px;

            .inputelement {
              top: 0;
              left: 0;

              &:not(:disabled) {
                cursor: pointer;
              }
            }
          }
        }

        .positions {
          padding-left: $grid-margin;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          margin: 0 !important;

          .tr {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            margin: 0 !important;
          }

          > .none-hover {
            .td {
              vertical-align: top;
              background-color: transparent !important;
            }
          }
        }
      }
    }

    .right {
      min-width: 200px;
      grid-area: right;

      .Table {
        width: 100%;
        overflow-y: scroll;

        .disable {
          color: grey;
        }

        .position-checkbox {
          width: 24px;

          .Input {
            box-shadow: none;
            min-height: 24px;
            min-width: 24px;
            width: 24px;

            .inputelement {
              top: 0;
              left: 0;

              &:not(:disabled) {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .dateGroup {
      margin: $grid-margin 0;

      .dateBtn {
        font-size: 32px;
        width: 160px;
      }
    }
  }

  > .Footer {
    > .btn-right {
      float: right;
    }

    > .btn-left {
      float: left;
      margin-right: $grid-margin;
    }
  }

  .rebookMessage {
    .p {
      font-size: 16px;
    }

    .checkIcon {
      color: green;
      font-weight: bold;
    }

    .closeIcon {
      color: red;
      font-weight: bold;
    }
  }
}
