.OwnerConditionMerchant {
  width: 100%;
  height: 100%;
  padding: $grid-margin;
  // direkt in der Klasse liegt ein Sheet Element, welches geändert werden soll
  > .Sheet {
    height: 100%;
  }
}

// geklaut von BookingList > bookingDetailsModal
.ownerConditionModal {
  > .Sheet {
    width: 80%;
    min-width: 850px;

    > .Body {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      //overflow: hidden;

      > .Table {
        //height: 50%;
        width: 100%;
      }
    }

    > .Footer {
      display: flex;

      > * {
        flex: 1 1 auto;
      }

      > .Button {
        flex: 0 0 auto;
        margin-left: $grid-margin;
      }
    }
  }
}

.phoneValidationFailedModal {
  > .Sheet {
    //width: 80%;
    //min-width: 850px;
    > .Footer {
      display: flex;

      > * {
        flex: 1 1 auto;
      }

      > .Button {
        flex: 0 0 auto;
        margin-left: $grid-margin;
      }
    }
  }
}
