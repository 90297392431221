.Topmenu {
  border-radius: 0;
  margin: 0;
  padding: $grid-margin;
  padding-right: 0;
  display: flex;
  flex-direction: row;
  line-height: $line-height*3;
  background-color: $color-brand;
  color: $color-text-light;

  > * {
    flex: 0 0 auto;
    margin-right: $grid-margin;
  }

  > .spacer {
    flex: 1 1 0;
  }

  > .toggleMenu {
    color: $color-text-light-secondary;
    width: $line-height*3;
    text-align: center;
    margin: 0;
  }

  > .logo {
    @extend h2;
    line-height: $line-height*3;
    margin: 0;
    background-image: url("./img/logo.svg");
    background-repeat: no-repeat;
    background-position: ($grid-margin - 1px) center;
    background-size: 175px auto;
    width: 200px;
  }

  > .title {
    @extend h2;
    line-height: $line-height*3;
    margin: 0;
    padding-left: $grid-margin*2;
    color: $color-text-light-secondary;
    flex: 0 0 auto;
    // opacity: 0.75;
  }

  > .version {
    line-height: $line-height*3;
    margin: 0;
    color: $color-text-light-secondary;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    // opacity: 0.75;

    > span {
      line-height: $line-height;
      font-size: 12px;
    }
  }

  > .help {
    color: $color-text-light-secondary;

    a {
      font-weight: bold;
      margin: 0 $grid-margin*2;
      color: $color-text-light;
    }
  }

  > .locales {
    padding-right: $grid-margin;

    > a {
      margin-right: $grid-margin;
      color: $color-text-light-secondary;

      &.active {
        font-weight: bold;
        color: $color-text-light;
      }
    }
  }

  > .logout {
    line-height: $line-height*3;
    color: $color-text-light-secondary;
    padding-right: $grid-margin*2;
  }
}
