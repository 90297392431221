.Edit {
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: $grid-margin;
  grid-template-areas: 'list list' 'actions main';
  grid-template-columns: auto 1fr;
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
  padding: $grid-margin;

  & .Edit {
    grid-template-rows: auto auto;
  }

  > * {
    margin: 0;
  }

  .Addon.icon-addon {
    width: $line-height*3;
    text-align: center;
    font-size: $font-size*2;

    > .Icon {
      font-size: $font-size*2;
    }

    > img {
      width: $line-height*2;
      height: $line-height*2;
      vertical-align: text-bottom;
      object-fit: contain;
    }
  }

  > .list {
    grid-area: list;
    display: grid;
    grid-gap: $grid-margin;
    grid-template-columns: minmax(240px, auto) 1fr auto auto auto;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'title search navigate pagesize reload' 'table table table table table';
    > .title {
      grid-area: title;

      > * {
        margin: 0;
      }

      > h2 {
        margin-bottom: $grid-margin*0.5;
      }

      > .sub {
        color: $color-text-secondary;
      }
    }

    > .table {
      grid-area: table;
      margin: 0;

      thead {
        white-space: nowrap;
      }

      td, th {
        cursor: pointer;
        white-space: nowrap;

        > img, i {
          margin-right: $grid-margin * 0.5;
        }
      }
    }

    > .search {
      grid-area: search;
    }

    > .navigate {
      grid-area: navigate;

      > .Input.type-select {
        width: $line-height*9;
      }
    }

    > .expand {
      grid-area: expand;
      display: none;
    }

    > .reload {
      grid-area: reload;
    }

    > .pagesize {
      grid-area: pagesize;
    }
  }

  > .actions {
    grid-area: actions;
    width: 240px;
    display: flex;
    flex-direction: column;

    > * {
      margin: 0;
    }

    > .editActions {
      flex: 1 1 0;
    }

    > .zoomActions {
      flex: 0 0 auto;
      margin-top: $grid-margin;
    }
  }

  > .main {
    grid-area: main;
    display: flex;
    flex-direction: row;

    > * {
      margin: 0;
    }

    > .form {
      flex: 1 1 0;
      overflow-y: scroll;
      padding-right: 0;
      @at-root .form {
        > .grid {
          display: grid;
          grid-gap: $grid-margin;
          //grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
          grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
          grid-auto-rows: $line-height*3;
          > .Input {
            min-height: $line-height*3;

            &.width-full {
              grid-column: 1 / -1;
            }

            &.type-textarea {
              //min-height: $line-height*9 + $grid-margin*2;
              height: auto !important;
              //grid-row: span 3;
            }
          }

          > .Table {
            grid-column: 1 / -1;
            grid-row: span 5;
            margin-top: -$grid-margin*0.5;
            margin-bottom: -$grid-margin*0.5;

            thead > tr:not(:first-child) > th {
              padding-top: 0;
              padding-left: $grid-margin;
            }

            /* td {
              cursor: pointer;
            } */
            .table-actions {
              width: $grid-margin;
            }
          }

          h3 {
            grid-column: 1 / -1;
            margin: 0;
          }
        }
      }
    }

    > .files {
      flex: 0 0 auto;
      width: 320px;
      margin-left: $grid-margin;

      .filelist {
        display: grid;
        grid-gap: $grid-margin;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: auto;

        > .file {
          position: relative;
          height: 148px;
          padding: $grid-margin;
          text-align: center;
          border: 1px solid $color-border;
          border-radius: $border-radius;
          /*background-image: linear-gradient(45deg, $color-background 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, $color-background 75%),
          linear-gradient(45deg, transparent 75%, $color-background 75%),
          linear-gradient(45deg, $color-background 25%, $color-border 25%);
          background-size: 20px 20px;
          background-position: 0 0, 0 0, -10px -10px, 10px 10px;*/
          background-color: transparentize($color-background, 0.5);

          > .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-origin: content-box;
          }

          > .open {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -$line-height*2+$grid-margin;
            margin-left: -$line-height*2+$grid-margin;
            background-color: $color-white;
            color: $color-text;
            cursor: pointer;
            border-radius: 50%;
            padding: $grid-margin;
            opacity: 0;
            transition: opacity 0.125s ease-in-out;
            font-size: $line-height*2;
            line-height: $line-height*2;

            &:hover {
              background-color: darken($color-white, 5%);
            }
          }

          > .delete {
            position: absolute;
            top: $grid-margin*0.5;
            right: $grid-margin*0.5;
            background-color: $color-error;
            color: $color-white;
            cursor: pointer;
            border-radius: 50%;
            padding: $grid-margin*0.5;
            opacity: 0;
            transition: opacity 0.125s ease-in-out;

            &:hover {
              background-color: darken($color-error, 5%);
            }
          }

          > .title {
            position: absolute;
            top: $grid-margin*0.5;
            left: $grid-margin*0.5;
            right: $grid-margin*0.5;
            text-align: left;
            line-height: $line-height + $grid-margin*0.5;

            > span {
              background-color: transparentize($color-white, 0.5);
            }
          }

          &:hover {
            > .open {
              opacity: 0.5;
            }

            > .delete {
              opacity: 1;
            }
          }
        }
      }
    }

    > .html {
      flex: 0 0 auto;
      width: 480px;
      margin-left: $grid-margin;
    }

    > .fkeys {
      flex: 0 0 auto;
      width: 240px;
      margin-left: $grid-margin;
    }
  }
}

.previewModal {
  .content {
    white-space: pre;
  }

  .Sheet {
    width: 90%;
  }

  .Footer {
    display: flex;

    > * {
      flex: 1 1 auto;
    }

    > .Button {
      flex: 0 0 auto;
      margin-left: $grid-margin;
    }
  }
}

.formModal {
  > .Sheet {
    // 'min' nicht klein schreiben, siehe https://css-tricks.com/when-sass-and-new-css-features-collide/#aa-the-solution
    min-width: Min(calc(100% - #{$grid-margin*2}), 1280px);

    > .form {
      display: flex;
      gap: $grid-margin;
      align-items: start;

      &.no-scroll {
        overflow: hidden;
      }

      > .grid {
        flex: 1 1 auto;
      }

      > .files {
        flex: 0 0 320px;
        box-shadow: none;
        padding: 0 0 0 $grid-margin;
        border: none;
        border-radius: 0;
        border-left: $color-border 1px solid;
        height: 100%;
      }

      > .html {
        flex: 0 0 480px;
        box-shadow: none;
        padding: 0 0 0 $grid-margin;
        border: none;
        border-radius: 0;
        border-left: $color-border 1px solid;
        height: 100%;
      }
    }
  }

  .Footer {
    display: flex;

    > * {
      flex: 1 1 auto;
    }

    > .Button {
      flex: 0 0 auto;
      margin-left: $grid-margin;
    }
  }
}

.alert-no-data {
  justify-content: start;
}

.MasterDataZoom {
  > .Sheet {
    overflow: hidden !important;
    width: 100%;
    height: 100%;
    /*display: flex;
    flex-direction: column;

    > .tabs {
      flex: 0 0 auto;

      > .ButtonGroup {
        display: inline-flex;
      }
    }

    > .Edit {
      flex: 1 1 0;
      height: auto !important;
    }*/
  }

  @for $i from 0 through 32 {
    &.depth-#{$i} > .Sheet {
      width: calc(100% - #{$line-height*2.5*$i} - #{$grid-margin});
      height: calc(100% - #{$line-height*4.5*$i} - #{$grid-margin});
    }
  }
}

.messageConfigModal {
  > .Sheet {
    min-width: 1000px;

    > .Footer {
      > .leftButton {
        float: left;
        margin-right: 8px;
      }

      > .rightButton {
        float: right;
      }
    }
  }
}

.messageListModal {

  > .Sheet {
    min-width: 1000px;

    > .Body {
      overflow: hidden;
      padding: 0 !important;
    }

    > .Footer {
      > .leftButton {
        float: left;
        margin-right: 8px;
      }

      > .rightButton {
        float: right;
      }
    }
  }
}

.bookingPlanModal {
  > .Sheet {
    min-width: 1000px;

    > .Body {
      padding: 0 !important;
      overflow: hidden !important;
    }

    > .Footer {
      > .Button {
        float: right;
      }
    }
  }
}

.previewNewsletterReceiverModal {
  > .Sheet {
    > .Footer {
      > .btn-right {
        float: right;
      }
    }
  }
}
