.imageModal {

  > .Sheet {
    width: 1024px;
    min-height: calc(75% - 16px);

    .Body {
      // padding: 0 !important;
      // overflow: hidden;
      background-color: transparentize($color-background, 0.5);

      img {
        margin: auto;
        display: block;
        max-width: 100%;
        max-height: calc(100vh - #{$line-height*3 + $grid-margin*6 + 1px});
      }
    }

    .Footer {
      display: flex;

      > * {
        flex: 1 1 auto;
      }

      > .Button {
        flex: 0 0 auto;
        margin-left: $grid-margin;
      }
    }
  }
}