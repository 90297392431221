.Dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: $grid-margin;

  #example-map {
    width: 500px;
    height: 500px;
  }

  > h1 {
    margin-bottom: $line-height;
    flex: 0 0 auto;
  }

  .tilewrap {
    flex: 1 1 0;
    overflow-y: scroll;
  }

  .tiles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-auto-rows: minmax(160px, auto);
    grid-gap: $grid-margin;
    padding-top: 0;
    grid-auto-flow: dense;

    > .tile {
      margin: 0;
      padding: $grid-margin 0;
      overflow: hidden;
      display: grid;
      grid-gap: 0;
      grid-auto-flow: row;
      grid-template-areas: "header" "body" "footer" "void";
      grid-template-rows: auto minmax(0, 1fr) auto 0;

      svg {
        vertical-align: bottom;
      }

      @supports not (display: grid) {
        display: flex;
        flex-direction: column;
        > * {
          flex: 0 0 0;
        }
        > .Header {
          flex: 0 0 auto;
        }
        > .Body {
          flex: 1 1 0;
        }
        > .Footer {
          flex: 0 0 auto;
        }
      }

      > * {
        grid-area: void;
      }

      > .Header {
        grid-area: header;
        color: $color-text-secondary;
      }

      > .Body {
        grid-area: body;
      }

      > .Footer {
        grid-area: footer;
      }

      > .Header,
      > .Body,
      > .Footer {
        padding: 0 $grid-margin;

        &.Header {
          padding-bottom: $grid-margin;
        }

        &.Body {
          overflow: hidden;
        }

        &.Footer {
          padding-top: $grid-margin;
        }

        > *:first-child {
          margin-top: 0;
        }

        > *:last-child {
          margin-bottom: 0;
        }
      }

      &.test {
        opacity: 0.5;
      }

      &.size-full-1 {
        grid-row: span 1;
        grid-column: 1 / -1;
      }

      &.size-full-2 {
        grid-row: span 2;
        grid-column: 1 / -1;
      }

      &.size-full-3 {
        grid-row: span 3;
        grid-column: 1 / -1;
      }

      &.size-1-1 {
        grid-row: span 1;
        grid-column: span 1;
      }

      &.size-1-2 {
        grid-row: span 2;
        grid-column: span 1;
      }

      &.size-1-3 {
        grid-row: span 3;
        grid-column: span 1;
      }

      &.size-2-1 {
        grid-row: span 1;
        //grid-column: span 2;
        grid-column: span unquote('min(2,var(--columns))');
      }

      &.size-2-2 {
        grid-row: span 2;
        //grid-column: span 2;
        grid-column: span unquote('min(2,var(--columns))');
      }

      &.size-2-3 {
        grid-row: span 3;
        //grid-column: span 2;
        grid-column: span unquote('min(2,var(--columns))');
      }

      &.size-3-1 {
        grid-row: span 1;
        //grid-column: span 3;
        grid-column: span unquote('min(3,var(--columns))');
      }

      &.size-3-2 {
        grid-row: span 2;
        //grid-column: span 3;
        grid-column: span unquote('min(3,var(--columns))');
      }

      &.size-3-3 {
        grid-row: span 3;
        //grid-column: span 3;
        grid-column: span unquote('min(3,var(--columns))');
      }

      &.size-4-1 {
        grid-row: span 1;
        //grid-column: span 4;
        grid-column: span unquote('min(4,var(--columns))');
      }

      &.size-4-2 {
        grid-row: span 2;
        //grid-column: span 4;
        grid-column: span unquote('min(4,var(--columns))');
      }

      &.size-4-3 {
        grid-row: span 3;
        //grid-column: span 4;
        grid-column: span unquote('min(4,var(--columns))');
      }
    }
  }
}