body,
html {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

.App {
  grid-template-areas: "navbar navbar" "menu topActions" "menu body" "menu bottomActions" "void void";
  grid-template-columns: minmax(0, min-content) minmax(0, 1fr);
  grid-template-rows: minmax(0, min-content) minmax(0, min-content) minmax(0, 1fr) minmax(0, min-content) 0;

  > .Topmenu {
    z-index: 3;
    grid-area: navbar;
  }

  > .Menu {
    grid-area: menu;
    width: 300px;
  }

  > .Body {
    z-index: 1;
    padding: 0;
    overflow-x: auto;
    overflow-y: auto;
  }

  .Alert {
    justify-content: start;
  }

  .login {
    max-width: 320px;
    margin: auto;
    margin-top: $grid-margin*16 !important;
    position: relative;
    overflow: visible;

    .Alert {
      word-break: break-word;
    }

    .info-username {
      color: $color-error;
      font-family: 'Indie Flower', cursive;
      font-size: $font-size*2;
      line-height: $line-height*2;
      position: absolute;
      top: $line-height*3.5;
      right: -$line-height*3;
      transform: translateX(100%) rotate(10deg);
      z-index: 1000;

      &::before {
        display: block;
        content: '-';
        position: absolute;
        top: 0;
        left: -$grid-margin;
        transform: scaleX(10);
        transform-origin: center right;
      }
    }

    .info-password {
      color: $color-error;
      font-family: 'Indie Flower', cursive;
      font-size: $font-size*2;
      line-height: $line-height*2;
      position: absolute;
      top: $line-height*6;
      right: -$line-height*3;
      transform: translateX(100%) rotate(5deg);
      z-index: 1000;

      &::before {
        display: block;
        content: '-';
        position: absolute;
        top: 0;
        left: -$grid-margin;
        transform: scaleX(10);
        transform-origin: center right;
      }
    }

    .info-login {
      color: $color-error;
      font-family: 'Indie Flower', cursive;
      font-size: $font-size*2;
      line-height: $line-height*2;
      position: absolute;
      top: $line-height*8.5;
      right: -$line-height*3;
      transform: translateX(100%);
      z-index: 1000;

      > div {
        margin-top: $grid-margin;
        text-align: center;

        > .Button {
          margin-right: $grid-margin;
          margin-bottom: $grid-margin;
          font-family: 'Indie Flower', cursive;

          &:nth-child(1) {
            transform: rotate(-5deg);
          }

          &:nth-child(2) {
            transform: rotate(10deg);
          }

          &:nth-child(3) {
            transform: rotate(5deg);
          }

          &:nth-child(5) {
            transform: rotate(5deg);
          }

          &:nth-child(6) {
            transform: rotate(-5deg);
          }

          &:nth-child(7) {
            transform: rotate(-10deg);
          }
        }
      }
    }
  }

  .demo {
    width: 480px;
    position: fixed;
    overflow: visible;
    bottom: 0;
    transform: translateY(100%) translateX(-50%);
    margin-bottom: $line-height*2+$grid-margin;
    left: 50%;
    transition: all 0.25s ease-in-out;

    &:hover,
    &:focus,
    &:focus-within {
      transform: translateY(0%) translateX(-50%);
      margin-bottom: $grid-margin;
    }

    .info-demo {
      color: $color-error;
      font-family: 'Indie Flower', cursive;
      font-size: $font-size*2;
      line-height: $line-height*2;
      position: absolute;
      top: -$line-height*3;
      left: -$line-height*6;
      transform: translateX(-100%) rotate(15deg);
      z-index: 1000;

      &::before {
        display: block;
        content: '-';
        position: absolute;
        top: 0;
        right: -$grid-margin*2;
        transform: scaleX(10);
        transform-origin: center left;
      }
    }
  }
}

.Icon {
  user-select: none;
}

.copy_text {
  font: inherit;
  color: inherit;
  text-decoration: underline;
  text-decoration-color: $color-border;

  .Icon {
    color: $color-border;
  }

  &:hover {
    color: inherit;
    text-decoration-color: $color-brand;

    .Icon {
      color: $color-brand;
    }
  }
}