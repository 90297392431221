.Support {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  > .tabs {
    flex: 0 0 auto;
    margin: $grid-margin $grid-margin 0;

    .filter-row {
      display: flex;

      > .Button, > .ButtonGroup {
        margin-right: 8px;
      }

      > .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .go-go-filter-button {
      flex: 0 0 auto;
    }
  }

  > .mask {
    flex: 1 1 auto;
    overflow: hidden;

    > .no-access {
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 8px;
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;

      > .message {
        height: min-content;
      }
    }
  }
}
