.Table {
  //height: 50%;
  width: 100%;
  overflow-y: initial;

  .badge{
    display: inline-block;
    margin-top: $grid-margin;
    margin-right: $grid-margin;
    padding: ($grid-margin/2 - 1px) ($grid-margin - 1px);
    background-color: $color-background;
    border: none;
    border-radius: $border-radius;
    box-shadow: inset 0 0 0 1px $color-border;
  }

  .position-checkbox {
    width: 24px;

    > .Input {
      box-shadow: none;
      min-height: 24px;
      min-width: 24px;
      width: 24px;

      > .inputelement {
        &:not(:disabled) {
          cursor: pointer;
        }
      }

      > .inputelement {
        top: 0;
        left: 0;
      }
    }
  }

  .feature-table, .feature-options-table {
    padding-right: 0;
    padding-left: 35px;

    .Table {
      width: 100%;
    }
  }
}

.hide {
  display: none;
}

.none-hover {
  > td {
    background-color: transparent !important;
  }
}
