.SumOfUnexportedPositions {
  padding: $grid-margin;
  height: 100%;

  > .Sheet {
    padding: $grid-margin;
    height: 100%;
    display: flex;
    flex-direction: column;

    .navigation {
      display: flex;
      gap: $grid-margin;

      .Input {
        width: 150px;
      }
    }

    .Progress {
      margin-top: $grid-margin;
    }

    .Table {
      margin-bottom: -$grid-margin;
      max-width: 404px;
    }
  }
}
