.voucher-redemption-history {

  > .Sheet {
    width: 80%;

    > .Header {
      > h2 {
        margin-bottom: $grid-margin*0.5;
      }

      > .sub {
        color: $color-text-secondary;
        display: grid;
        grid-template-columns: 1fr 1fr ;
      }
    }

    > .Body {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 100%;
      overflow: hidden;
      //overflow-y: scroll;

      > .Table {
        height: 100%;
        width: 100%;
        //overflow-y: initial;
      }
    }

    > .Footer {
      > .btnClose {
        float: right;
      }
    }
  }
}
