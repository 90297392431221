.bookingDetailsModal {
  > .Sheet {
    width: 80%;
    min-width: 850px;
    height: 100%;

    > .Body {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 100%;
      overflow-y: scroll;

      &.documents {
        overflow: hidden;

        .Table {
          width: 100%;
          height: 100%;
        }
      }

      > .Row {
        height: 100%;
      }

      .Pdf {
        border-radius: 0;
      }

      > .Table {
        //height: 50%;
        width: 100%;
        overflow-y: initial;

        .position-checkbox {
          width: 24px;

          > .Input {
            box-shadow: none;
            min-height: 24px;
            min-width: 24px;
            width: 24px;

            > .inputelement {
              &:not(:disabled) {
                cursor: pointer;
              }
            }

            > .inputelement {
              top: 0;
              left: 0;
            }
          }
        }

        .feature-table, .feature-options-table {
          padding-right: 0;
          padding-left: 35px;

          .Table {
            width: 100%;
          }
        }
      }
    }

    > .Footer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: $grid-margin;

      > .buttons {
        display: flex;
        gap: $grid-margin;
        flex-wrap: wrap;
      }

      > .cancellation-in-progress {
        flex-grow: 1;
      }
    }
  }
}

.UserModal {
  > .Sheet {
    min-width: 850px;

    > .Body {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 100%;
      overflow-y: scroll;

      > .Table {
        width: 100%;
        overflow-y: initial;
      }

      > .Progress {
        margin-top: $grid-margin;
        margin-bottom: $grid-margin;
      }
    }

    > .Footer {
      > .btnClose {
        float: right;
      }
    }
  }
}

.hide {
  display: none;
}

.none-hover {
  > td {
    background-color: transparent !important;
  }
}

.details-header {
  margin-top: $grid-margin * 2;
  display: flex;
  align-items: center;

  > h2 {
    flex: 1 1 auto;
  }

  > .add-protocol {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    width: fit-content;
    height: 32px;
  }
}

.resendPaymentMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  width: fit-content !important;
  height: 32px;
}

.paymentMessageModal {
  .Footer {
    display: flex;
    justify-content: flex-end;

    > .Button {
      flex: 0 0 auto;

      &:not(.go-go-magic-button) {
        margin-right: $grid-margin;
      }
    }
  }
}

.refundModal {
  .error-info {
    color: $color-error;
  }

  .overwritten {
    > .Input {
      width: 200px;
    }
  }
}

.manualPaymentModal {
  .error-info {
    color: $color-error;
  }

  .overwritten {
    margin-bottom: $grid-margin;
    > .Input {
      width: 200px;
    }
  }
}
