.Dac7ExportList {
  width: 100%;
  height: 100%;
  padding: $grid-margin;

  > * {
    margin: 0;
  }

  .Addon.icon-addon {
    width: $line-height*3;
    text-align: center;
    font-size: $font-size*2;

    > .Icon {
      font-size: $font-size*2;
    }

    > img {
      width: $line-height*2;
      height: $line-height*2;
      vertical-align: text-bottom;
      object-fit: contain;
    }
  }

  > .list {
    height: 100%;
    grid-area: list;
    display: grid;
    grid-gap: $grid-margin;
    grid-template-columns: minmax(240px, auto) 1fr auto auto auto;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'title  title navigate pagesize reload' 'table table table table table';

    > .title {
      grid-area: title;

      > * {
        margin: 0;
      }

      > h2 {
        margin-bottom: $grid-margin*0.5;
      }

      > .sub {
        color: $color-text-secondary;
      }
    }

    > .table {
      grid-area: table;
      margin: 0;
      overflow-x: scroll;
      overflow-y: scroll;
      width: calc(100% + #{$grid-margin});
      height: calc(100% + #{$grid-margin});

      thead {
        white-space: nowrap;
      }

      td, th {
        white-space: nowrap;

        > img, i {
          margin-right: $grid-margin * 0.5;
        }
      }

      .booking {
        cursor: pointer;
      }

      .booking-list-error {
        justify-content: center;
      }
    }

    > .navigate {
      grid-area: navigate;

      > .Input.type-select {
        width: $line-height*9;
      }
    }

    > .expand {
      grid-area: expand;
      display: none;
    }

    > .reload {

      &.loading {

        @keyframes rotate {
          from {
            transform: rotate(0);
          }
          to {
            transform: rotate(360deg);
          }
        }

        > .Icon {
          animation-name: rotate;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
    }

    > .pagesize {
      grid-area: pagesize;
    }
  }

  > .actions {
    grid-area: actions;
    width: 240px;
    display: flex;
    flex-direction: column;

    > * {
      margin: 0;
    }

    > .editActions {
      flex: 1 1 0;
    }

    > .zoomActions {
      flex: 0 0 auto;
      margin-top: $grid-margin;
    }
  }

  > .main {
    grid-area: main;
    display: flex;
    flex-direction: row;

    > * {
      margin: 0;
    }
  }
}

.hide {
  display: none;
}

.none-hover {
  > td {
    background-color: transparent !important;
  }
}

.dac7ExportSend {
  width: 1px;
  padding-right: $grid-margin;
}

.sendExport {
  display: flex;
  align-items: center;
  justify-content: center;
  //float: right;
  width: fit-content !important;
  height: 32px;
  margin-right: 8px !important;
}
