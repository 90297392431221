.passwordResetModalEmail, .passwordResetModalPassword {
  > .Sheet {
    min-width: 400px;

    .Input {
      margin-top: $grid-margin;
      margin-bottom: $grid-margin;
    }

    .errorMessage {
      text-align: center;
      color: red;
    }

    .infoMessage {
      text-align: center;
      color: green;
    }

    > .Footer {
      display: flex;
      justify-content: space-between;

      > .Progress {
        width: 100%;
      }
    }
  }
}
