.OwnerPaymentsMenu {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  > .tabs {
    flex: 0 0 auto;
    margin: $grid-margin $grid-margin 0;
  }

  > .mask {
    flex: 1 1 auto;
    overflow: hidden;
    margin: $grid-margin;

    > .no-access {
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 8px;
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;

      > .message {
        height: min-content;
      }
    }
  }
}

.dataDetailsModal, .paymentDetailsModal {
  > .Sheet {
    width: 80%;
    min-width: 850px;
    height: 100%;

    > .Body {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      overflow: hidden;

      > .Row {
        height: 100%;
      }

      .Pdf {
        border-radius: 0;
      }

      .Table {
        height: 100%;
        width: 100%;
      }
    }

    > .Footer {
      > .btn-left {
        float: left;
      }

      > .input-left {
        float: left;
        width: 200px;
        margin-left: $grid-margin;
      }

      > .btn-right {
        float: right;
      }
    }
  }
}

.OwnerPaymentList {
  width: 100%;
  height: 100%;

  > .list {
    height: 100%;
    width: 100%;
    grid-area: list;
    display: grid;
    padding: $grid-margin;
    grid-template-columns: minmax(240px, auto);
    grid-template-rows: auto auto 1fr;
    grid-template-areas: 'title' 'filter' 'data-table';

    > .title, {
      grid-area: title;
    }

    > .search-bar {
      grid-area: filter;
    }

    > .data-table {
      grid-area: data-table;
      width: 100%;
    }
  }
}

.OwnerInvoice {
  width: 100%;
  height: 100%;

  > .list {
    height: 100%;
    width: 100%;
    grid-area: list;
    display: grid;
    padding: $grid-margin;
    grid-template-columns: minmax(240px, auto);
    grid-template-rows: auto auto 1fr;
    grid-template-areas: 'title' 'filter' 'data-table';

    > .title, {
      grid-area: title;
    }

    > .search-bar {
      grid-area: filter;
    }

    > .data-table {
      grid-area: data-table;
      width: 100%;
    }
  }
}

.PendingOwnerInvoice {
  width: 100%;
  height: 100%;

  > .list {
    height: 100%;
    width: 100%;
    grid-area: list;
    display: grid;
    padding: $grid-margin;
    grid-template-columns: minmax(240px, auto);
    grid-template-rows: auto auto 1fr;
    grid-template-areas: 'title' 'filter' 'data-table';

    > .title, {
      grid-area: title;
    }

    > .search-bar {
      grid-area: filter;
    }

    > .Table {
      grid-area: data-table;
      width: 100%;
    }
  }
}
