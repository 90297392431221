.files {

  .filelist {
    display: grid;
    grid-gap: $grid-margin;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;

    > .file {
      position: relative;
      height: 148px;
      padding: $grid-margin;
      text-align: center;
      border: 1px solid $color-border;
      border-radius: $border-radius;
      /*background-image: linear-gradient(45deg, $color-background 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, $color-background 75%),
      linear-gradient(45deg, transparent 75%, $color-background 75%),
      linear-gradient(45deg, $color-background 25%, $color-border 25%);
      background-size: 20px 20px;
      background-position: 0 0, 0 0, -10px -10px, 10px 10px;*/
      background-color: transparentize($color-background, 0.5);

      > .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: content-box;
      }

      > .open {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -$line-height*2+$grid-margin;
        margin-left: -$line-height*2+$grid-margin;
        background-color: $color-white;
        color: $color-text;
        cursor: pointer;
        border-radius: 50%;
        padding: $grid-margin;
        opacity: 0;
        transition: opacity 0.125s ease-in-out;
        font-size: $line-height*2;
        line-height: $line-height*2;

        &:hover {
          background-color: darken($color-white, 5%);
        }
      }

      > .delete {
        position: absolute;
        top: $grid-margin*0.5;
        right: $grid-margin*0.5;
        background-color: $color-error;
        color: $color-white;
        cursor: pointer;
        border-radius: 50%;
        padding: $grid-margin*0.5;
        opacity: 0;
        transition: opacity 0.125s ease-in-out;

        &:hover {
          background-color: darken($color-error, 5%);
        }
      }

      > .title {
        position: absolute;
        top: $grid-margin*0.5;
        left: $grid-margin*0.5;
        right: $grid-margin*0.5;
        text-align: left;
        line-height: $line-height + $grid-margin*0.5;

        > span {
          background-color: transparentize($color-white, 0.5);
        }
      }

      &:hover {
        > .open {
          opacity: 0.5;
        }

        > .delete {
          opacity: 1;
        }
      }
    }
  }
}