.PagedSearchTable {
  width: 100%;
  height: 100%;

  &.list-only {
    display: grid;
    grid-template-areas: 'list list' 'list list';
    grid-template-columns: auto 1fr;
    grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
    padding: 0;

    > .list {
      height: 100%;
      grid-area: list;
      display: grid;
      grid-gap: $grid-margin;
      grid-template-columns: minmax(240px, auto) 1fr auto auto auto;
      grid-template-rows: auto 1fr;
      grid-template-areas: 'title children navigate pagesize reload' 'table table table table table';

      > .title {
        grid-area: title;

        > * {
          margin: 0;
        }

        > h2 {
          margin-bottom: $grid-margin*0.5;
        }

        > .sub {
          color: $color-text-secondary;
        }
      }

      > .table {
        grid-area: table;
        margin: 0;

        thead {
          white-space: nowrap;
        }

        td, th {
          white-space: nowrap;

          > img, i {
            margin-right: $grid-margin * 0.5;
          }
        }
      }

      > .search {
        grid-area: search;
      }

      > .navigate {
        grid-area: navigate;
        height: fit-content;

        > .Input.type-select {
          width: $line-height*9;
        }
      }

      > .reload {
        grid-area: reload;
      }

      > .pagesize {
        grid-area: pagesize;
      }

      > .children {
        grid-area: children;
      }
    }
  }

  &.list-only-padding {
    @extend .list-only;
    padding: 8px;
  }

  &.list {
    display: grid;
    grid-template-areas: 'list list' 'list list';
    grid-template-columns: auto 1fr;
    grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
    padding: 0;

    > .list {
      height: 100%;
      grid-area: list;
      display: grid;
      grid-gap: $grid-margin;
      grid-template-columns: minmax(240px, auto) 1fr auto auto auto;
      grid-template-rows: auto 1fr;
      grid-template-areas: 'title children navigate pagesize reload' 'table table table table table';

      > .title {
        grid-area: title;

        > * {
          margin: 0;
        }

        > h2 {
          margin-bottom: $grid-margin*0.5;
        }

        > .sub {
          color: $color-text-secondary;
        }
      }

      > .table {
        grid-area: table;
        margin: 0;

        thead {
          white-space: nowrap;
        }

        td, th {
          cursor: pointer;
          white-space: nowrap;

          > img, i {
            margin-right: $grid-margin * 0.5;
          }
        }
      }

      > .search {
        grid-area: search;
      }

      > .navigate {
        grid-area: navigate;
        height: fit-content;

        > .Input.type-select {
          width: $line-height*9;
        }
      }

      > .reload {
        grid-area: reload;
      }

      > .pagesize {
        grid-area: pagesize;
      }

      > .children {
        grid-area: children;
      }
    }
  }

  &.list-padding {
    @extend .list-only;
    padding: 8px;
  }
}
