.BookingCalendar {
  width: 100%;
  height: 100%;
  padding: $grid-margin;

  > * {
    margin: 0;
  }

  > .list {
    height: 100%;
    grid-area: list;
    display: grid;
    grid-gap: $grid-margin;
    grid-template-columns: minmax(240px, auto) 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'title span reload' 'table table table';

    > .title {
      grid-area: title;

      > * {
        margin: 0;
      }

      > h2 {
        margin-bottom: $grid-margin*0.5;
      }

      > .sub {
        color: $color-text-secondary;
      }
    }

    > .table {
      grid-area: table;
      margin: 0;

      thead {
        white-space: nowrap;
      }

      td, th {
        cursor: pointer;
        white-space: nowrap;
        width: 1%;

        > img, i {
          margin-right: $grid-margin * 0.5;
        }
      }
    }

    > .reload {
      grid-area: reload;
    }
  }
}
