.ErrorBound {
  margin: $grid-margin;

  .alert-context {
    width: 100%;

    > .alert-header {
      display: flex;

      > h2 {
        flex: 1 1 auto;
      }

      > .reset {
        cursor: pointer;
        height: 48px;
        width: 48px;

        > .Icon {
          height: 32px;
          width: 32px;
          font-size: 32px;
          line-height: 32px;
          color: $color-white;
        }
      }
    }
  }
}
