.CustomerContactPersonsWithBadLegitimations {
  overflow: hidden;
  height: 100%;
  padding: $grid-margin;

  > .Sheet {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: $grid-margin;

    .loading-error {
      justify-content: center;
      margin-top: 0;
    }

    > .input-row {
      display: flex;
      gap: $grid-margin;

      > .Input {
        width: 200px;
      }
    }
  }
}
