.voucher-redemption-history {
  > .Sheet {
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 20px;

    > .Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #e0e0e0;

      > h2 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
        color: #333;
      }

      > .sub {
        color: $color-text-secondary;
        font-size: 0.9rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    > .Body {
      padding: 0;
      height: auto;
      overflow-y: auto;
      max-height: 60vh;

      > .Table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
        background-color: #fafafa;

        thead {
          background-color: #f0f0f0;

          th {
            padding: 12px;
            text-align: left;
            font-weight: 600;
            color: #555;
            border-bottom: 2px solid #ddd;
          }
        }

        tbody {
          tr {
            transition: background-color 0.3s ease;

            &:hover {
              background-color: #f5f5f5;
              cursor: pointer; // Click-Zeiger
            }

            td {
              padding: 12px;
              border-bottom: 1px solid #eee;
              color: #666;
            }
          }
        }
      }
    }

    > .Footer {
      padding-top: 20px;
      display: flex;
      justify-content: flex-end;

      > .btnClose {
        padding: 10px 20px;
        background-color: #f44336;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #d32f2f;
        }
      }
    }
  }
}


.PromocodeList {
  width: 100%;
  height: 100%;
  padding: $grid-margin;

  > * {
    margin: 0;
  }

  .Addon.icon-addon {
    width: $line-height * 3;
    text-align: center;
    font-size: $font-size * 2;

    > .Icon {
      font-size: $font-size * 2;
    }

    > img {
      width: $line-height * 2;
      height: $line-height * 2;
      vertical-align: text-bottom;
      object-fit: contain;
    }
  }

  > .list {
    display: grid;
    grid-template-columns: auto 1fr auto auto auto;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'title search navigate pagesize reload' 'table table table table table';
    grid-gap: $grid-margin;
    height: 100%;

    > .title {
      grid-area: title;

      > * {
        margin: 0;
      }

      > h2 {
        margin-bottom: $grid-margin * 0.5;
        font-size: 1.4rem;
        color: #333;
      }

      > .sub {
        color: $color-text-secondary;
      }
    }

    > .table {
      grid-area: table;
      overflow-x: auto;
      overflow-y: auto;
      width: calc(100% + #{$grid-margin});
      height: calc(100% + #{$grid-margin});

      thead {
        white-space: nowrap;
        background-color: #f7f7f7;

        th {
          padding: 10px;
          font-weight: 600;
          color: #333;
        }
      }

      tbody {
        white-space: nowrap;

        tr {
          &:hover {
            background-color: #eaeaea;
            cursor: pointer; // Click-Zeiger beim Hover
          }

          td {
            padding: 10px;
            color: #555;
          }
        }
      }
    }

    > .search {
      grid-area: search;
    }

    > .navigate {
      grid-area: navigate;

      > .Input.type-select {
        width: $line-height * 9;
      }
    }

    > .reload {
      &.loading {
        @keyframes rotate {
          from {
            transform: rotate(0);
          }
          to {
            transform: rotate(360deg);
          }
        }

        > .Icon {
          animation-name: rotate;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
    }

    > .pagesize {
      grid-area: pagesize;
    }
  }

  > .actions {
    grid-area: actions;
    display: flex;
    flex-direction: column;
    width: 240px;

    > * {
      margin: 0;
    }

    > .editActions {
      flex: 1 1 0;
    }

    > .zoomActions {
      flex: 0 0 auto;
      margin-top: $grid-margin;
    }
  }
}

.table-row-hover {
  cursor: pointer;
}


