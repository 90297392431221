.rebookingModal {
  > .Sheet {
    width: 80%;
    min-width: 850px;
    height: 100%;

    > .Body {
      height: 100%;
      width: 100%;
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      .rebookingContainer {
        overflow: scroll;
        display: flex;
        flex-direction: column;
        gap: 50px;
      }
    }

    > .Footer {
      > .btn-right {
        float: right;
      }

      > .btn-left {
        float: left;
        margin-right: $grid-margin;
      }
    }

    .rebookMessage {
      .p {
        font-size: 16px;
      }

      .checkIcon {
        color: green;
        font-weight: bold;
      }

      .closeIcon {
        color: red;
        font-weight: bold;
      }
    }
  }
}
