.taxModal {
  > .Sheet {
    min-width: Min(calc(100% - #{$grid-margin*2}), 1280px);

    > .Body {
      padding: 0 !important;
      overflow: hidden;

      > .form {
        border: none;
      }
    }
  }

  .Footer {
    display: flex;

    > * {
      flex: 1 1 auto;
    }

    > .Button {
      flex: 0 0 auto;
      margin-left: $grid-margin;
    }
  }
}