.BookingPlan {
  width: 100%;
  height: 100%;

  &:not(.modal) {
    padding: $grid-margin;
  }

  > * {
    margin: 0;
  }

  > .list {
    height: 100%;
    grid-area: list;
    display: grid;
    grid-gap: $grid-margin;
    grid-template-columns: minmax(240px, auto) 1fr 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'title search span reload' 'table table table table';

    > .title {
      grid-area: title;

      > * {
        margin: 0;
      }

      > h2 {
        margin-bottom: $grid-margin*0.5;
      }

      > .sub {
        color: $color-text-secondary;
      }
    }

    > .table {
      grid-area: table;
      margin: 0;

      thead {
        white-space: nowrap;
      }

      td, th {
        cursor: pointer;
        white-space: nowrap;

        > img, i {
          margin-right: $grid-margin * 0.5;
        }
      }
    }

    > .search {
      grid-area: search;
    }

    > .reload {
      grid-area: reload;
    }
  }

  .Table {
    flex: 1 1 auto;

    & table thead {
      box-shadow: none;
    }

    th, td {
    }

    .dates {
      position: relative;
      overflow: hidden;
      margin: -$grid-margin 0;
      height: $line-height+$grid-margin*2;

      .date {
        position: absolute;
        top: 0;
        left: 0;
        padding: $grid-margin;
        border-left: 1px solid $color-border;
      }
    }

    .details {
      position: relative;
      overflow: hidden;
      margin: -$grid-margin 0;
      height: $line-height+$grid-margin*2 + 1px;

      .detail {
        position: absolute;
        top: 1px;
        left: 0;
        padding: $grid-margin - 1px;
        margin-bottom: 1px;
        border-left: px solid $color-white;
        border-right: px solid $color-white;
        color: $color-black;
        background-color: $color-border;
        border-left: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: $border-radius;

        &.BLOCK {
          color: $color-white;
          background-color: $color-error;
        }

        &.BOOKING {
          color: $color-white;
          background-color: $color-success;
        }

        &.ICAL_IMPORT {
          color: $color-white;
          background-color: $color-info;
        }

        &.BOOKING_EXTERNAL {
          color: $color-white;
          background-color: $color-primary;
        }
      }
    }
  }
}
