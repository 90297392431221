.MessageList {
  width: 100%;
  height: 100%;
  padding: $grid-margin;

  > * {
    margin: 0;
  }

  .Addon.icon-addon {
    width: $line-height*3;
    text-align: center;
    font-size: $font-size*2;

    > .Icon {
      font-size: $font-size*2;
    }

    > img {
      width: $line-height*2;
      height: $line-height*2;
      vertical-align: text-bottom;
      object-fit: contain;
    }
  }

  > .list {
    height: 100%;
    grid-area: list;
    display: grid;
    grid-gap: $grid-margin;
    grid-template-columns: minmax(240px, auto) 1fr auto auto auto;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'title search navigate pagesize reload' 'table table table table table';

    > .title {
      grid-area: title;

      > * {
        margin: 0;
      }

      > h2 {
        margin-bottom: $grid-margin*0.5;
      }

      > .sub {
        color: $color-text-secondary;
      }
    }

    > .table {
      grid-area: table;
      margin: 0;

      thead {
        white-space: nowrap;
      }

      td, th {
        cursor: pointer;
        white-space: nowrap;

        > img, i {
          margin-right: $grid-margin * 0.5;
        }
      }
    }

    > .search {
      grid-area: search;
    }

    > .navigate {
      grid-area: navigate;

      > .Input.type-select {
        width: $line-height*9;
      }
    }

    > .expand {
      grid-area: expand;
      display: none;
    }

    > .reload {
      grid-area: reload;
    }

    > .pagesize {
      grid-area: pagesize;
    }
  }

  > .actions {
    grid-area: actions;
    width: 240px;
    display: flex;
    flex-direction: column;

    > * {
      margin: 0;
    }

    > .editActions {
      flex: 1 1 0;
    }

    > .zoomActions {
      flex: 0 0 auto;
      margin-top: $grid-margin;
    }
  }

  > .main {
    grid-area: main;
    display: flex;
    flex-direction: row;

    > * {
      margin: 0;
    }
  }
}

.messageDetailModal {
  > .Sheet {
    width: 80%;
    min-width: 850px;

    > .Body {
      > .Table {
        //height: 50%;
        width: 100% !important;
      }

      .filelist {
        display: grid;
        grid-gap: $grid-margin;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        grid-auto-rows: minmax(160px, auto);

        > .file {
          position: relative;
          height: 160px;
          padding: $grid-margin;
          text-align: center;
          border: 6px solid $color-border;
          border-radius: $border-radius;
          /*background-image: linear-gradient(45deg, $color-background 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, $color-background 75%),
          linear-gradient(45deg, transparent 75messageDetailModal%, $color-background 75%),
          linear-gradient(45deg, $color-background 25%, $color-border 25%);
          background-size: 20px 20px;
          background-position: 0 0, 0 0, -10px -10px, 10px 10px;*/
          background-color: transparentize($color-background, 0.5);

          > .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-origin: content-box;
          }

          > .open {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -$line-height*2+$grid-margin;
            margin-left: -$line-height*2+$grid-margin;
            background-color: $color-white;
            color: $color-text;
            cursor: pointer;
            border-radius: 50%;
            padding: $grid-margin;
            opacity: 0;
            transition: opacity 0.125s ease-in-out;
            font-size: $line-height*2;
            line-height: $line-height*2;

            &:hover {
              background-color: darken($color-white, 5%);
            }
          }

          > .delete {
            position: absolute;
            top: $grid-margin*0.5;
            right: $grid-margin*0.5;
            background-color: $color-error;
            color: $color-white;
            cursor: pointer;
            border-radius: 50%;
            padding: $grid-margin*0.5;
            opacity: 0;
            transition: opacity 0.125s ease-in-out;

            &:hover {
              background-color: darken($color-error, 5%);
            }
          }

          > .title {
            position: absolute;
            top: $grid-margin*0.5;
            left: $grid-margin*0.5;
            right: $grid-margin*0.5;
            text-align: left;
            line-height: $line-height + $grid-margin*0.5;

            > span {
              background-color: transparentize($color-white, 0.5);
            }
          }

          &:hover {
            > .open {
              opacity: 0.5;
            }

            > .delete {
              opacity: 1;
            }
          }
        }
      }

      .resend-email-alert {
        height: 100%;
      }
    }

    > .Footer {
      display: flex;

      > * {
        flex: 1 1 auto;
      }

      > .Button {
        flex: 0 0 auto;

        &:not(.go-go-magic-button) {
          margin-left: $grid-margin;
        }
      }
    }
  }
}

.protocol-modal {
  > .Sheet {
    min-width: 50%;

    .Body {
      overflow: hidden;

      > .Table {
        width: 100%;
        height: 100%;
      }
    }

    .Footer {
      > .Button {
        float: right;
        margin-left: $grid-margin;
      }
    }
  }
}

.MasterDataZoom {
  > .Sheet {
    overflow: hidden !important;
    width: 100%;
    height: 100%;
  }

  @for $i from 0 through 32 {
    &.depth-#{$i} > .Sheet {
      width: calc(100% - #{$line-height*2.5*$i} - #{$grid-margin});
      height: calc(100% - #{$line-height*4.5*$i} - #{$grid-margin});
    }
  }
}

.EmployeeModal {
  > .Sheet {
    min-width: 850px;

    > .Body {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 100%;
      overflow-y: scroll;

      > .Table {
        width: 100%;
        overflow-y: initial;
      }
    }

    > .Footer {
      > .btnClose {
        float: right;
      }
    }
  }
}
